<template>
    <div class="flex">
        <div>
        <el-form
            :model="Form"
            :rules="rules"
            ref="ruleForm"
            label-width="180px"
            v-loading="loading"
        >
            <el-row :gutter="30">
                <el-col :span="12">
                    <el-form-item label="协议通证买入手续费:" prop="agreementBuyServerChange">
                        <el-input v-model="Form.agreementBuyServerChange" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="协议通证卖出手续费:" prop="agreementSaleServerChange">
                        <el-input v-model="Form.agreementSaleServerChange" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="用户持有原价通证上限:" prop="maxNum">
                        <el-input v-model="Form.maxNum" ></el-input>
                    </el-form-item>
                </el-col>
                 <!-- <el-col :span="12">
                    <el-form-item label="协议通证用户买卖类型:" prop="agreementUserType">
                        <el-radio-group v-model="Form.agreementUserType">
                            <el-radio :label="1">只能买</el-radio>
                            <el-radio :label="2">只能卖</el-radio>
                            <el-radio :label="3">可以买也可以卖</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col> -->
               
                <el-col :span="12">
                    <el-form-item label="原价通证买入手续费:" prop="originalBuyServerChange">
                        <el-input v-model="Form.originalBuyServerChange" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="原价通证卖出手续费:" prop="originalSaleServerChange">
                        <el-input v-model="Form.originalSaleServerChange" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                <el-form-item label="划拨通证手续费:" prop="getServerChange">
                <el-input v-model="Form.getServerChange "></el-input>
                </el-form-item>
            </el-col>
                <el-col :span="12">
                    <el-form-item label="原价通证用户买卖类型:" prop="originalUserType">
                        <el-radio-group v-model="Form.originalUserType">
                            <el-radio :label="1">只能买</el-radio>
                            <el-radio :label="2">只能卖</el-radio>
                            <el-radio :label="3">可以买也可以卖</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                 <el-col :span="12">
                    <el-form-item label="转入最大值:" prop="inMax">
                        <el-input v-model="Form.inMax" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="转入最小值:" prop="inMin">
                        <el-input v-model="Form.inMin" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="转出最大值:" prop="tokenMax">
                        <el-input v-model="Form.tokenMax" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="转出最小值:" prop="tokenMin">
                        <el-input v-model="Form.tokenMin" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="互转最大值:" prop="transferMax">
                        <el-input v-model="Form.transferMax" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="互转最小值" prop="transferMin">
                        <el-input v-model="Form.transferMin" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="转出手续费:" prop="transferOutServerChange">
                        <el-input v-model="Form.transferOutServerChange" ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="" style="display:flex;justify-content:center;margin-top:50px">
         <el-button type="primary"   @click='submit()' >提交</el-button>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            Form:{},
            rules:{},
            loading:false,
            genId:"",
            id:''
        }
    },
    mounted(){
        if(this.$route.query.genId){
            this.genId=this.$route.query.genId
            this.getInfo()
        }
        
    },
    methods:{
        getInfo(){
            this.axios.get('/admin/genRule/findByGENId',{id:this.genId}).then(res=>{
                if(res.data.data){
                    this.Form=res.data.data
                    this.id=this.Form.id
                }
                
            })
        },
        submit(){
            this.loading=true
            if(this.id){
                this.axios.put('/admin/genRule/updateGENRule',this.Form).then(res=>{
                    this.loading=false
                    if(res.code==200){
                            this.$router.push('gen')
                        }else{
                            this.$message(res.mesg)
                        }
                })
            }else{
                
                this.axios.post('/admin/genRule/addGENRule',{...this.Form,genId:this.genId})
                .then(res=>{
                        this.loading=false
                        if(res.data.code==200){
                            this.$router.push('gen')
                        }else{
                            this.$message(res.data.mesg)
                        }
                })
            }
            
        },
    }
}


</script>

<style lang="scss" scoped>
.flex {
  padding: 50px;
  width: 1500px;
  display: flex;
  // background-color: red;
  height: 200px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 5px;
  border: 1px dashed #8c939d;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
  border-radius: 5px;
}
</style>